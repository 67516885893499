<template>
  <div>
    <v-layout wrap justify-center class="mainfont">
      <v-snackbar v-model="showSnackBar" color="black">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: white">
              {{ msg }}
            </span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: white">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <vue-element-loading :active="appLoading" :is-full-screen="true" background-color="#FFFFFF" color="#283e51"
        spinner="spinner" />
      <v-flex xs12>
        <v-layout wrap>
          <v-flex xs2 pl-6 pt-5>
            <span style="font-size: 25px;font-weight: bolder;">IB PROGRAMS</span>
          </v-flex>
          <v-flex xs10 pt-5>
            <v-layout wrap justify-end>

              <v-flex xs3 pt-3>
        
    </v-flex>

              <v-flex xs3 pt-3 pl-4>
      <v-form @submit.prevent="validateInput">
        <v-autocomplete
          clearable
          dense
          outlined
          label="Circle"
          color="black"
          :items="circle"
          v-model="circleid"
          item-text="officeName"
          item-value="_id"
          item-color="#FF1313"
          hide-details
          class="custom-autocomplete mainfont"
        >
          <template v-slot:append>
            <v-icon color="black">mdi-chevron-down</v-icon>
          </template>
        </v-autocomplete>
      </v-form>
    </v-flex>

    <v-flex xs3 pt-3 pl-4>
      <v-form @submit.prevent="validateInput">
        <v-autocomplete
          clearable
          dense
          style="border-radius: 0%;"
          outlined
          label="Division"
          color="black"
          :items="division"
          v-model="divisionid"
          item-text="officeName"
          item-value="_id"
          item-color="#FF1313"
          hide-details
          class="custom-autocomplete mainfont"
          :disabled="!circleid" 
        >
          <template v-slot:append>
            <v-icon color="black">mdi-chevron-down</v-icon>
          </template>
        </v-autocomplete>
      </v-form>
    </v-flex>

    <v-flex xs3 pt-3 pr-4 pl-4>
      <v-form @submit.prevent="validateInput">
        <v-autocomplete
          clearable
          dense
          style="border-radius: 0%;"
          outlined
          label="Range"
          color="black"
          :items="range"
          v-model="rangeid"
          item-text="officeName"
          item-value="_id"
          item-color="#FF1313"
          hide-details
          class="custom-autocomplete mainfont"
          :disabled="!divisionid" 
        >
          <template v-slot:append>
            <v-icon color="black">mdi-chevron-down</v-icon>
          </template>
        </v-autocomplete>
      </v-form>
    </v-flex>

            </v-layout> 

          </v-flex>

        </v-layout>

        <v-layout v-if="list.length > 0" pl-3 pr-4  wrap>

          <v-flex pl-3 v-for="(item, i) in list" :key="i" text-center xs11 lg3 class="mainbg2" pt-3>
            <v-card class="elevation-3">
              <v-card-text class="pa-0">
                <v-layout wrap justify-center>

                  <v-flex xs12>
                    <v-img height="100%" contain src="../../assets/Images/ibimage12.jpg"></v-img>

                  </v-flex>
                  <v-flex xs11 pt-3 text-left style="font-size: 23px;color: #000000;font-weight: bold;">
                    {{ item.accName }}
                  </v-flex>


                  <v-flex xs11 pt-2 text-left style="font-weight: bold;">
                    <strong>No of Rooms</strong>
                    <span style="color: #000000;font-size: 17px;">
                      &nbsp; {{ item.noOfRooms }}

                    </span>
                  </v-flex>


                  <v-flex xs11 text-right pt-6>
                    <v-btn block elevation="0" color="#283e51" @click="$router.push('/editib?id=' + item._id)">
                      <span style="color:white;">Edit</span>

                    </v-btn>
                  </v-flex>
                  <v-flex xs11 text-right pt-2 pb-4>
                    <v-btn block elevation="0" color="red" @click="(deletedialog = true), (curid = item._id)">
                      <span style="color:white;">Delete</span>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
     
      </v-flex>
    </v-layout>
    <v-layout pa-4>
      <v-flex>
        <v-pagination prev-icon="mdi-menu-left" next-icon="mdi-menu-right" :length="pages" :total-visible="7"
          v-model="currentPage" color="#283e51"></v-pagination>
      </v-flex>
    </v-layout>
    <v-dialog :retain-focus="true" persistent v-model="dialog2" max-width="350px">
      <v-card class="mainfont" style="color: #000000; font-size: 16px; font-weight: 500">
        <v-layout wrap justify-center>
          <v-flex>
            <v-layout pa-4 wrap justify-center>
              <v-flex xs12 text-right>
                <v-icon @click="dialog2 = false" color="red" size="150%" dark>
                  mdi-close
                </v-icon>
              </v-flex>
              <v-flex xs9 pt-4>
                <span style="color: black; font-size: 16px; font-weight: bold">Accommodation Name</span>
                <v-form @submit.prevent="validateInput">
                  <v-text-field color="#283e51" outlined background-color="white" dense v-model="accName"
                    hide-details></v-text-field>
                </v-form>
              </v-flex>
              <v-flex xs9 pt-4>
                <span style="color: black; font-size: 16px; font-weight: bold">No of Rooms</span>
                <v-form @submit.prevent="validateInput">
                  <v-text-field color="#283e51" outlined background-color="white" dense type="number" v-model="noOfRooms"
                    hide-details>
                  </v-text-field>
                </v-form>
              </v-flex>



              <v-flex xs9 pt-3>
                <span style="color: black; font-size: 16px; font-weight: bold">Max Guests per Room</span>
                <v-form @submit.prevent="validateInput">
                  <v-text-field color="#283e51" outlined background-color="white" dense type="number"
                    v-model="maxGuestPerRoom" hide-details></v-text-field>
                </v-form>
              </v-flex>

              <v-flex xs9 text-center pt-4>
                <v-btn @click="addIb()" dark width="100px" color="#283e51">
                  <span style="color: white; font-size: 14px">Add</span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>

    <v-dialog :retain-focus="true" persistent v-model="editdailog" max-width="350px">
      <v-card>
        <v-layout class="mainfont" wrap justify-center pa-3>
          <v-flex>
            <v-layout wrap justify-center>

              <v-flex xs12 text-right>
                <v-icon @click="editdailog = false" color="red" size="150%" dark>
                  mdi-close
                </v-icon>
              </v-flex>
              <v-flex xs9 pt-4>
                <span style="color: black; font-size: 16px; font-weight: bold">Accommodation Name</span>
                <v-form @submit.prevent="validateInput">
                  <v-text-field color="black" outlined background-color="white" dense type="text" v-model="curid.accName"
                    hide-details></v-text-field>
                </v-form>
              </v-flex>

              <v-flex xs9 pt-4>
                <span style="color: black; font-size: 16px; font-weight: bold">Number of Rooms</span>
                <v-form @submit.prevent="validateInput">
                  <v-text-field color="black" outlined background-color="white" dense type="number"
                    v-model="curid.noOfRooms" hide-details></v-text-field>
                </v-form>
              </v-flex>



              <v-flex xs9 pt-4>
                <span style="color: black; font-size: 16px; font-weight: bold">Max Guests per Room</span>
                <v-form @submit.prevent="validateInput">
                  <v-text-field color="black" outlined background-color="white" dense type="number"
                    v-model="curid.maxGuestPerRoom" hide-details></v-text-field>
                </v-form>
              </v-flex>

              <v-flex xs9 text-center pt-4>
                <v-btn dark width="100px" color="#283e51" @click="edit()">
                  <span style="color: white; font-size: 14px;">Edit</span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-dialog :retain-focus="true" persistent v-model="deletedialog" max-width="600px">
      <v-card>
        <v-card-title class="mainfont" style="color: black; font-size: 18px; font-weight: lighter">Are you sure you want
          to delete this IB?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="deletedialog = false" class="mainfont">Cancel</v-btn>
          <v-btn color="red" class="mainfont" text @click="deleteItem()">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";

export default {


  data() {
    return {
      name: null,
      barlist: [],
      userProfileTabs: null,
      subtab: null,









      appLoading: false,
      page: 1,
      pages: 0,
      dialog1: false,

      preview: null,
      tab: null,
      barlist1: [],
      dialog3: false,
      deletedialog: false,
      editdailog: false,

      minAmount: null,
      password: null,
      categoryarray: [],
      list: [],
      flag: false,
      flag2: false,
      auctionbased: [],
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      limit: 10,
      fromDate: null,
      toDate: null,
      fromDate2: null,
      toDate2: null,
      noOfRooms: null,
      range: [],
      division: [],
      accName: null,
      divisionid: null,
      circleid: null,
      rangeid: null,
      maxGuestPerRoom: null,

      circle: [],
      msg: null,
      currentPage: 1,

      showSnackBar: false,
      curid: [],
      dialog2: false,


    };
  },
  watch: {
    rangeid() {
      this.getList();
    },

    circleid(newVal) {
      if (!newVal) {
        // Clear both Division and Range fields when Circle is cleared
        this.divisionid = null;
        this.rangeid = null;
      }
      this.getDivision();
      this.getList();

    },

    divisionid(newVal) {
      if (!newVal) {
        // Clear Range field when Division is cleared
        this.rangeid = null;
      }
      this.getRange();
      this.getList();

    },

    currentPage() {
      this.getList();
    },
  },

  mounted() {
    this.getList();
    this.getCircle();


  },


  methods: {

    getDivision() {
      this.appLoading = true;
      const selectedCircle = this.circle.find(c => c._id === this.circleid);
      if (selectedCircle) {
        const path = selectedCircle.path;
        axios({
          method: "post",
          url: "/get/divsions",
          headers: {
            token: localStorage.getItem("token"),
          },
          data: {
            path: path, // Include the 'path' parameter here
          },
        })
          .then((response) => {
            this.division = response.data.data;
            this.appLoading = false;
          })
          .catch((err) => {
            this.ServerError = true;
            console.log(err);
          });
      }
    },

    getRange() {
      this.appLoading = true;
      const selectedDivision = this.division.find(d => d._id === this.divisionid);
      if (selectedDivision) {
        const path = selectedDivision.path;

        axios({
          method: "post",
          url: "/get/range",
          headers: {
            token: localStorage.getItem("token"),
          },
          data: {
            divisionid: this.divisionid,
            path: path,
          },
        })
          .then((response) => {
            this.range = response.data.data;
            this.appLoading = false;
          })
          .catch((err) => {
            this.ServerError = true;
            console.log(err);
          });
      }
    },
    deleteItem() {
      axios({
        url: "/delete/accomodation",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          rangeid: this.rangeid,
          circleid: this.circleid,
          divisionid: this.divisionid,
          accid: this.curid,
        }
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.deletedialog = false;
            window.location.reload();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getCircle() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/get/circles",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {

        },

      })
        .then((response) => {
          this.circle = response.data.data;

          this.appLoading = false;
        })

        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    edit() {
      axios({
        method: "POST",
        url: "/edit/accomodation",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          noOfRooms: this.curid.noOfRooms,
          accName: this.curid.accName,
          maxGuestPerRoom: this.curid.maxGuestPerRoom,
          accid: this.curid._id,

        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.editdailog = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
            location.reload();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },

    addIb() {
      axios({
        method: "POST",
        url: "/add/accomodation",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          noOfRooms: this.noOfRooms,
          accName: this.accName,
          maxGuestPerRoom: this.maxGuestPerRoom,

        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.dialog2 = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
            location.reload();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },

    getList() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/all/accomodations",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          rangeid: this.rangeid,
          circleid: this.circleid,
          divisionid: this.divisionid,
          status: this.status,
          categoryId: this.categoryId,
          page: this.currentPage,
          fromDate: this.fromDate,
          toDate: this.toDate,
          limit: 12,
        },
      })
        .then((response) => {
          this.list = response.data.data;
          this.totalData = response.data.totalLength;
          this.pages = Math.ceil(this.totalData / response.data.limit);
          this.appLoading = false;
        })

        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },

  },
};
</script>

<style >
.cus3 {
  height: 50px;
  background-color: white;
}

.vertical-divider {
  border-right: 1px solid black;
  height: 70px;
}

.v-date-picker .v-picker__body {
  box-shadow: none;
}

#chart-container {
  background: linear-gradient(180deg, #29807c 0%, rgba(5, 112, 107, 0.16) 100%);
}

/* Add some styling to the cards */
.elevation-3 {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: 0.3s box-shadow;
}

.elevation-3:hover {
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.2);
}

.headline {
  font-size: 18px;
  font-weight: bold;
}
</style>